ul.form-collection {
  list-style: none;
  padding: 0;

  li {
    padding: 10px;
    background: #f2f2f2;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

ul.pagination {
  padding: 0 0 0 15px !important;
}

td ol {
  -webkit-padding-start: 20px !important;
}
td ul {
  -webkit-padding-start: 20px !important;
}

.submenu {
  padding: 0 0 0 15px !important;
  margin-left: 60px !important;
}

textarea {
  resize: none;
}

.table {
  > tbody > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: $font-weight-normal;
    padding-bottom: 5px;
  }
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}